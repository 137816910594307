import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PasswordRecoveryService {
  baseUrl = environment.apiRecord;
  constructor(private http: HttpClient) { }

  sendEmail(email: any): Promise<any>{
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };
    return  this.http.post( `${this.baseUrl}/api/v1/password/token`, email, httpOptions).toPromise();
  }

  resetPassword(password: any): Promise<any>{
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };
    return  this.http.put( `${this.baseUrl}/api/v1/password/reset`, password, httpOptions).toPromise();
  }
}
